export const apps = [
    { icon: '/img/apps/youtubetv.png', name: 'YouTube TV' },
    { icon: '/img/apps/nba.png', name: 'NBA League Pass' },
    { icon: '/img/apps/espn.png', name: 'ESPN+' },
    { icon: '/img/apps/hulu.png', name: 'Hulu' },
    { icon: '/img/apps/fubo.png', name: 'FuboTV' },
    { icon: '/img/apps/sling.png', name: 'Sling' },
    { icon: '/img/apps/twitch.png', name: 'Twitch' },
    { icon: '/img/apps/paramount.png', name: 'Paramount+' },
    { icon: '/img/apps/mlb.png', name: 'MLB TV' },
    { icon: '/img/apps/marchmadness.png', name: 'March Madness Live' },
];
