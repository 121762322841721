import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { rem, transparentize } from 'polished';
import FadeIn from 'components/FadeIn';
import * as Typography from 'styles/typography';
import validator from 'email-validator';
import CheckIcon from '../../public/img/check.svg';

interface IEmailSubmission {
    className?: string;
    id?: string;
    submitCopy: string;
    submitted: boolean;
    onSubmit?: (email: string) => void;
}

const VALIDATION_ERROR_MESSAGE = 'Please enter a valid email address';

const EmailSubmission: React.FunctionComponent<IEmailSubmission> = (props) => {
    const { className, id, submitCopy, submitted, onSubmit } = props;

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isValid, setIsValid] = useState(false);
    const input = useRef<HTMLInputElement>(null);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isValid) {
            setErrorMessage(VALIDATION_ERROR_MESSAGE);
            return;
        }

        if (submitted) {
            return;
        }

        input.current!.blur();

        if (onSubmit) {
            onSubmit(email);
        }
    };

    useEffect(() => {
        setIsValid(email ? validator.validate(email) : false);
    }, [email]);

    return (
        <EmailSubmissionStyled className={className} id={id} onSubmit={handleSubmit} noValidate>
            <EmailInputStyled
                ref={input}
                error={!!errorMessage}
                id="email_input"
                type="email"
                name="Email"
                value={email}
                placeholder="Email address"
                disabled={submitted}
                onChange={(e) => {
                    setEmail(e.target.value);
                    setErrorMessage(null);
                }}
            />
            <SubmitButtonStyled type="submit" aria-label="Submit email" success={submitted}>
                {submitted && <CheckIcon />}
                {submitted ? 'Submitted' : submitCopy}
            </SubmitButtonStyled>
            {errorMessage && (
                <ErrorMessageStyled>
                    <p>{errorMessage}</p>
                </ErrorMessageStyled>
            )}
        </EmailSubmissionStyled>
    );
};

const EmailInputStyled = styled.input<{ error: boolean }>`
    ${({ theme }) => theme.typography.FontNormal};
    font-size: ${rem(16)};
    color: ${({ error, theme }) => (error ? theme.palette.Red : theme.palette.White)};
    flex-grow: 1;
    min-width: 0;
    height: ${rem(72)};
    line-height: ${rem(72)};
    border-radius: ${rem(36)};
    padding: 0 ${rem(32)};
    display: flex;
    align-items: center;
    margin-bottom: ${rem(16)};
    background-color: ${({ theme }) => theme.palette.Black};
    border: ${rem(1)} solid ${({ theme }) => theme.palette.MedGrey4};
    transition: border-color 100ms ease;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        font-size: ${rem(18)};
        height: ${rem(50)};
        line-height: ${rem(50)};
        padding: 0 ${rem(20)};
        margin-bottom: 0;
        background-color: transparent;
        border-radius: none;
        border: none;
    }

    ::placeholder {
        ${({ theme }) => theme.typography.FontNormal};
        color: ${({ theme, error }) => (error ? theme.palette.Red : theme.palette.LightGrey1)};
        font-size: ${rem(16)};

        @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
            font-size: ${rem(18)};
        }
    }

    &:focus {
        border-color: ${({ theme }) => theme.palette.White};
    }

    ${({ error, theme }) =>
        error &&
        css`
            border-color: ${theme.palette.Red};

            &:focus {
                border-color: ${theme.palette.Red};
            }
        `}
`;

const SubmitButtonStyled = styled.button<{ success: boolean }>`
    ${({ theme }) => theme.typography.FontMedium};
    font-size: ${rem(18)};
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: ${rem(60)};
    border-radius: ${rem(30)};
    flex-shrink: 0;
    color: ${({ theme }) => theme.palette.White};
    background-color: ${({ theme, success }) =>
        success ? theme.palette.Green : theme.palette.Red};
    transition: background-color 150ms ease;

    svg {
        height: ${rem(20)};
        width: ${rem(20)};
        margin-right: ${rem(5)};
    }

    .svg_content {
        fill: ${({ theme }) => theme.palette.White};
    }

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme, success }) =>
                transparentize(0.2, success ? theme.palette.Green : theme.palette.Red)};
        }
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        width: ${rem(190)};
        height: ${rem(50)};
        border-radius: ${rem(25)};
        font-size: ${rem(13)};
    }
`;

const ErrorMessageStyled = styled(FadeIn)`
    ${Typography.Text14}
    color: ${({ theme }) => theme.palette.Red};
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    text-align: center;
    padding-top: ${rem(10)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        ${Typography.Text16}
        color: ${({ theme }) => theme.palette.Red};
        padding-top: ${rem(12)};
    }
`;

const EmailSubmissionStyled = styled.form`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        position: relative;
        flex-direction: row;
        align-items: center;
        padding: ${rem(11)};
        height: ${rem(72)};
        box-sizing: border-box;
        border-radius: ${rem(36)};
        background-color: ${({ theme }) => theme.palette.Black};
    }
`;

export default EmailSubmission;
