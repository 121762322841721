import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

interface MarketingChatBubble {
    className?: string;
    visible: boolean;
    poster?: string;
    mobileVisible: boolean;
    delay: number;
    src: string;
    preload?: boolean;
    autoPlay?: boolean;
    onCanPlay?: () => void;
}

const MarketingChatBubble: React.FunctionComponent<MarketingChatBubble> = ({
    className,
    visible,
    poster,
    mobileVisible,
    delay,
    src,
    preload = false,
    autoPlay = false,
    onCanPlay = () => undefined,
}) => {
    const video = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (src) {
            video.current.load();
        }
    }, [src]);

    return (
        <MarketingChatBubbleStyled
            className={className}
            visible={visible}
            mobileVisible={mobileVisible}
            delay={delay}
        >
            <AvatarStyled
                poster={poster}
                ref={video}
                src={src}
                muted
                autoPlay={autoPlay}
                loop
                playsInline
                controls={false}
                preload={preload ? 'auto' : 'none'}
                onCanPlayThrough={onCanPlay}
            />
        </MarketingChatBubbleStyled>
    );
};

const AvatarStyled = styled.video`
    height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.palette.LightGrey1};
`;

const MarketingChatBubbleStyled = styled.div<{
    visible: boolean;
    delay?: number;
    mobileVisible: boolean;
}>`
    width: ${rem(100)};
    height: ${rem(100)};
    border-radius: 50%;
    box-sizing: border-box;
    border: ${rem(2)} solid rgba(0, 0, 0, 0.5);
    overflow: hidden;
    position: relative;
    opacity: 0;
    transform: translateY(100%);
    transition: transform 200ms ease-out, opacity 200ms ease-out;

    ${({ mobileVisible, delay }) =>
        mobileVisible &&
        css`
            opacity: 1;
            transform: translateX(0);
            transition: transform 200ms ease-in ${delay ? `${delay}s` : '0s'},
                opacity 200ms ease-in ${delay ? `${delay}s` : '0s'};
        `}

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        border: ${rem(4)} solid rgba(0, 0, 0, 0.5);

        ${({ visible, delay }) =>
            visible &&
            css`
                opacity: 1;
                transform: translateX(0);
                transition: transform 200ms ease-in ${delay ? `${delay}s` : '0s'},
                    opacity 200ms ease-in ${delay ? `${delay}s` : '0s'};
            `}
    }
`;

export default MarketingChatBubble;
