import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

interface MarketingFeatureDetails {
    className?: string;
    index: number;
    visible: boolean;
    icon: React.ReactNode;
    heading: string;
    description: string;
}

const MarketingFeatureDetails: React.ForwardRefRenderFunction<
    HTMLDivElement,
    MarketingFeatureDetails
> = ({ className, index, visible, icon, heading, description }, ref) => (
    <MarketingFeatureDetailsStyled className={className} index={index} ref={ref} visible={visible}>
        <IconStyled>{icon}</IconStyled>
        <HeadingStyled>{heading}</HeadingStyled>
        <DescriptionStyled>{description}</DescriptionStyled>
    </MarketingFeatureDetailsStyled>
);

const DescriptionStyled = styled.p`
    color: ${({ theme }) => theme.palette.LightGrey1};
    font-size: ${rem(14)};
    text-align: left;
    line-height: 1.5;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.XXLarge)}) {
        font-size: ${rem(22)};
    }
`;

const HeadingStyled = styled.h2`
    font-size: ${rem(28)};
    color: ${({ theme }) => theme.palette.White};
    margin-bottom: ${rem(12)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.XXLarge)}) {
        font-size: ${rem(36)};
    }
`;

const IconStyled = styled.div`
    height: ${rem(32)};
    width: ${rem(32)};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.GreenTransparent1};
    margin-bottom: ${rem(20)};

    svg {
        height: 63%;
        width: 63%;
        color: ${({ theme }) => theme.palette.Green};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.XXLarge)}) {
        height: ${rem(50)};
        width: ${rem(50)};
    }
`;

const MarketingFeatureDetailsStyled = styled.div<{ index: number; visible: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ${rem(20)} 0;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        height: 100vh;
        box-sizing: border-box;
        padding-top: calc(50vh + ${rem(40)});
        scroll-snap-align: center;
        top: ${({ index }) => -1 * (index + 1) * 50}vh;
        position: relative;
        opacity: ${({ visible }) => (visible ? 1 : 0)};
        transition: opacity 500ms ease;
        justify-content: center;
        padding-top: 0;
        top: ${({ index }) => -1 * index * 33.33}vh;
    }
`;

export default forwardRef(MarketingFeatureDetails);
