import React from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { rem } from 'polished';
import Link from 'next/link';
import AuthButtons from './AuthButtons';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { track } from '../state/app';
import { TrackingContext } from '../typescript/typings';

interface Nav {
    className?: string;
    fixed?: boolean;
    signup?: boolean;
    login?: boolean;
}

const Nav: React.FunctionComponent<Nav> = (props) => {
    const { className, fixed = true, signup = true, login = true } = props;

    const dispatch = useAppDispatch();
    const mobile = useAppSelector((state) => state.app.mobile);

    return (
        <>
            {fixed && <FixedParentMargin />}
            <NavStyled className={className} id="nav" fixed={fixed}>
                <Link href="/">
                    <LogoContainerStyled href="/" aria-label="Playback Home">
                        <img src="/img/logo-beta.svg" alt="Playback" />
                    </LogoContainerStyled>
                </Link>
                {(signup || login) && (
                    <AuthButtonsStyled
                        login={login && !mobile}
                        signup={signup}
                        onSignup={() => {
                            dispatch(
                                track({
                                    event: 'Click Sign Up',
                                    context: TrackingContext.Nav,
                                })
                            );
                        }}
                        onLogin={() => {
                            dispatch(
                                track({
                                    event: 'Click Log In',
                                    context: TrackingContext.Nav,
                                })
                            );
                        }}
                    />
                )}
            </NavStyled>
        </>
    );
};

const AuthButtonsStyled = styled(AuthButtons)`
    margin-left: ${rem(20)};
`;

const FixedParentMargin = createGlobalStyle`
    #pb_layout {
        margin-top: ${rem(72)};

        @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
            margin-top: ${rem(80)};
        }
    }
`;

const LogoContainerStyled = styled.a`
    height: ${rem(22)};
    width: ${rem(240)};

    img {
        height: 100%;
        width: 100%;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Medium)}) {
        height: ${rem(24)};
        width: ${rem(260)};
        display: flex;
    }
`;

const NavStyled = styled.nav<{ fixed: boolean }>`
    height: ${rem(72)};
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 ${rem(24)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        height: ${rem(80)};
    }

    ${({ fixed }) =>
        fixed &&
        css`
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            transition: background-color 200ms ease;
        `}
`;

export default Nav;
