import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

interface IFadeIn {
    className?: string;
    children: React.ReactNode;
    duration?: number;
}

const FadeIn: React.FunctionComponent<IFadeIn> = ({ className, children, duration = 200 }) => {
    const [opacity, setOpacity] = useState(typeof window === 'undefined' ? 1 : 0);

    useEffect(() => {
        setOpacity(1);
    }, []);

    return (
        <FadeInStyled className={className} opacity={opacity} duration={duration}>
            {children}
        </FadeInStyled>
    );
};

const FadeInStyled = styled.div<{ opacity: number; duration: number }>`
    opacity: ${({ opacity }) => opacity};
    transition: opacity ${({ duration }) => duration}ms ease;
`;

export default FadeIn;
