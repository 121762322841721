import { css } from 'styled-components';
import HeadingBase from './HeadingBase';

export default css`
    ${HeadingBase}
    font-family: 'Grotesque Bold', Helvetica, Arial, sans-serif;
    font-size: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
`;
