import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import FilledButton from 'packages/common/base/components/FilledButton';
import TextButton from 'packages/common/base/components/TextButton';
import UserIcon from '../public/img/user.svg';
import { showModal } from 'state/app';
import { ModalType } from 'typescript/typings';
import { useAppDispatch } from 'state/hooks';

interface AuthButtons {
    className?: string;
    custom?: boolean;
    format?: FilledButton['format'];
    size?: FilledButton['size'];
    signup?: boolean;
    login?: boolean;
    onSignup?: () => void;
    onLogin?: () => void;
}

const AuthButtons: React.FunctionComponent<AuthButtons> = (props) => {
    const {
        className,
        custom = true,
        format = 'neutral',
        size = 'lg',
        signup = true,
        login = true,
        onSignup,
        onLogin,
    } = props;

    const dispatch = useAppDispatch();

    return (
        <AuthButtonsStyled className={className}>
            {signup && (
                <SignupButtonStyled
                    custom={custom}
                    size={size}
                    format={format}
                    onClick={() => {
                        dispatch(showModal({ type: ModalType.Signup }));
                        if (onSignup) {
                            onSignup();
                        }
                    }}
                >
                    <UserIcon /> Sign up
                </SignupButtonStyled>
            )}
            {login && signup && (
                <LoginButtonStyled
                    onClick={() => {
                        dispatch(showModal({ type: ModalType.Login }));
                        if (onLogin) {
                            onLogin();
                        }
                    }}
                >
                    Log in
                </LoginButtonStyled>
            )}
            {login && !signup && (
                <SignupButtonStyled
                    custom={custom}
                    size={size}
                    format={format}
                    onClick={() => {
                        dispatch(showModal({ type: ModalType.Login }));
                        if (onLogin) {
                            onLogin();
                        }
                    }}
                >
                    <UserIcon /> Log in
                </SignupButtonStyled>
            )}
        </AuthButtonsStyled>
    );
};

const SignupButtonStyled = styled(FilledButton)<{ custom: boolean }>`
    ${({ custom }) =>
        custom &&
        css`
            @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
                height: ${rem(50)};
                border-radius: ${rem(25)};
            }
        `}
`;

const LoginButtonStyled = styled(TextButton)`
    margin-left: ${rem(12)};
    flex-shrink: 0;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-left: ${rem(16)};
    }
`;

const AuthButtonsStyled = styled.div`
    display: flex;
    align-items: center;
`;

export default AuthButtons;
