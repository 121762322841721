import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { useRouter } from 'next/router';
import { rem, transparentize } from 'polished';
import {
    AppComponent,
    ReferralContext,
    AdCampaignSource,
    REFERRAL_CONTEXT_NAME,
    TrackingContext,
    ModalType,
} from 'typescript/typings';
import SEO from 'components/SEO';
import Nav from 'components/Nav';
import Footer from 'components/Footer';
import StandardLayout from 'components/StandardLayout';
import MarketingFeatureDetails from 'components/landing/MarketingFeatureDetails';
import MarketingChatBubble from 'components/landing/MarketingChatBubble';
import { useInView } from 'react-intersection-observer';
import Logo from '../public/img/logo-beta.svg';
import LogoMini from '../public/img/logo-mini.svg';
import HandIcon from '../public/img/wave.svg';
import MicIcon from '../public/img/mic.svg';
import PlayIcon from '../public/img/play.svg';
import CameraIcon from '../public/img/camera.svg';
import PhoneIcon from '../public/img/iphone.svg';
import { apps } from '../constants/landing';
import throttle from 'lodash.throttle';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { showModal, track, updateUser } from 'state/app';
import AuthButtons from 'components/AuthButtons';
import { USER_EVENTS } from '../constants/tracking';
import Breakpoints from 'packages/common/base/styles/breakpoints';
import dynamic from 'next/dynamic';
import EmailSubmission from '../components/landing/EmailSubmission';

const BetaEmailOverlay = dynamic(() => import('components/landing/BetaEmailOverlay'), {
    ssr: false,
});

interface Landing {
    className?: string;
}

interface RouteQuery {
    ac?: AdCampaignSource;
    // Encoded user data, e.g. if user opens page from invite email link
    u?: string;
    // Encoded referrer data, e.g. if user opens page from host referrer link
    r?: string;
    // On-site referral link context
    p?: ReferralContext;
}

interface DecodedUserData {
    email?: string;
    firstName?: string;
    id?: string;
}

const VIDEO_STATE_DURATION = 3000;

const getUserData = async (dataString: string): Promise<DecodedUserData> => {
    if (!dataString) {
        return {};
    }

    const Tracking = (await import('packages/common/base/utils/tracking')).default;
    const data = Tracking.decodeUserDataString(dataString);
    return typeof data === 'object' ? data : {};
};

const showMobileAssets = () =>
    typeof window === 'undefined'
        ? null
        : !window.matchMedia(`(min-width: ${rem(Breakpoints.Small)})`).matches;

const Landing: AppComponent<Landing> = (props) => {
    const router = useRouter();

    const { className } = props;
    const { u, r, p, ac } = router.query as RouteQuery;

    const dispatch = useAppDispatch();
    const loggedIn = useAppSelector((state) => !!state.user.accessToken);

    const [userData, setUserData] = useState<DecodedUserData>({});
    const [referrerData, setReferrerData] = useState<DecodedUserData>({});
    const [darkenVideo, setDarkenVideo] = useState(false);
    const [videoState, setVideoState] = useState(0);
    const [heroVideoLoaded, setHeroVideoLoaded] = useState(false);
    const [navScrolled, setNavScrolled] = useState(false);
    const [showEmailOverlay, setShowEmailOverlay] = useState(false);
    const [ssr, setSsr] = useState(true);
    const featureVideo = useRef<HTMLVideoElement>(null);
    const heroVideo = useRef<HTMLVideoElement>(null);
    const canvas = useRef<HTMLCanvasElement>(null);
    const heroSection = useRef<HTMLDivElement>(null);
    const featureSection = useRef<HTMLDivElement>(null);

    const referralLink =
        typeof p !== 'undefined'
            ? REFERRAL_CONTEXT_NAME[parseInt(p as unknown as string) as ReferralContext]
            : null;

    const mobile = showMobileAssets();

    const heroVideoSrc = ssr ? null : `/video/messi${mobile ? '_mobile' : ''}.mp4`;
    const avatar1Src = ssr ? null : `/video/avatar_01${mobile ? '_mobile' : ''}.mp4`;
    const avatar2Src = ssr ? null : `/video/avatar_02${mobile ? '_mobile' : ''}.mp4`;
    const avatar3Src = ssr ? null : `/video/avatar_03${mobile ? '_mobile' : ''}.mp4`;

    const handleSubmitEmail = async (email: string) => {
        dispatch(
            updateUser({
                email,
                requestedBeta: true,
                source: 'Beta landing page',
            })
        );

        dispatch(
            track({
                event: USER_EVENTS.REQUEST_CREATOR_APPLICATION,
                email,
                source: 'Beta landing page',
                referrer: document.referrer,
                referrerId: referrerData.id,
                referralLink,
                campaign: ac === AdCampaignSource.Facebook ? 'fb' : null,
            })
        );
    };

    const [captureRef, captureInView] = useInView({
        threshold: 0.67,
    });

    const [videoChatRef, videoChatInView] = useInView({
        threshold: 0.67,
    });

    const [devicesRef, devicesInView] = useInView({
        threshold: 0.67,
    });

    const [videoInViewRef, videoInView] = useInView({
        threshold: 0.5,
    });

    const [featureSectionInViewRef, featureSectionInView] = useInView();

    const setVideoRef = useCallback(
        (node: HTMLVideoElement) => {
            videoInViewRef(node);
            featureVideo.current = node;
        },
        [videoInViewRef]
    );

    const setFeatureSectionRef = useCallback(
        (node: HTMLDivElement) => {
            featureSectionInViewRef(node);
            featureSection.current = node;
        },
        [featureSectionInView]
    );

    const getHeading = () => {
        if (referrerData.firstName) {
            return `${referrerData.firstName} invited you to join Playback`;
        }

        if (userData.email) {
            return 'Welcome to the Playback Beta';
        }

        return 'Watch live sports together';
    };

    const handleHeroSectionVideoCanPlay = () => {
        if (heroVideoLoaded) {
            return;
        }

        const videos = Array.from(heroSection.current.querySelectorAll('video'));
        const allVideosPlayable = videos.every((video) => video.readyState === 4);

        if (allVideosPlayable) {
            videos.forEach((video) => {
                video.play();
            });

            setHeroVideoLoaded(true);
        }
    };

    useEffect(() => {
        setSsr(false);
    }, []);

    useEffect(() => {
        if (u) {
            const handleEmailLink = async () => {
                const data = await getUserData(u);
                setUserData(data);
                dispatch(
                    track({
                        event: 'Opened Email Link',
                        email: data.email,
                    })
                );
            };

            handleEmailLink();
        }
    }, [u, dispatch]);

    useEffect(() => {
        if (r) {
            const handleReferrerLink = async () => {
                const data = await getUserData(r);
                setReferrerData(data);
                dispatch(track({ event: 'Opened Referrer Link', referrerEmail: data.email }));
            };

            handleReferrerLink();
        }
    }, [r, dispatch]);

    useEffect(() => {
        if (heroVideoSrc) {
            heroVideo.current?.load();
        }
    }, [heroVideoSrc]);

    useEffect(() => {
        if (videoInView) {
            const interval = setInterval(() => {
                setVideoState((i) => (i === 3 ? 0 : i + 1));
            }, VIDEO_STATE_DURATION);

            return () => {
                clearInterval(interval);
                setVideoState(0);
            };
        }
    }, [videoInView]);

    useEffect(() => {
        if (videoChatInView) {
            setDarkenVideo(true);

            const lightenTimeout = setTimeout(() => {
                setDarkenVideo(false);
            }, 3000);

            return () => {
                clearTimeout(lightenTimeout);
                setDarkenVideo(false);
            };
        }
    }, [videoChatInView]);

    useEffect(() => {
        if (ssr) {
            return;
        }

        if (mobile ? featureSectionInView && videoState === 3 : devicesInView) {
            const width = Math.floor(canvas.current.clientWidth * 2);
            const height = Math.floor(canvas.current.clientHeight * 2);
            canvas.current.width = width;
            canvas.current.height = height;

            const mirrorVideo = () => {
                canvas.current
                    .getContext('2d')
                    .drawImage(featureVideo.current, 0, 0, width, height);
            };

            const interval = setInterval(mirrorVideo, 20);

            return () => {
                clearInterval(interval);
            };
        }
    }, [ssr, mobile, videoState, featureSectionInView, devicesInView]);

    useEffect(() => {
        const handleScroll = throttle(() => {
            const scrolledToTop = window.scrollY === 0;

            if (scrolledToTop) {
                setNavScrolled(false);
            } else {
                setNavScrolled(true);
            }

            setNavScrolled(!scrolledToTop);
        }, 300);

        handleScroll();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (featureSectionInView) {
            featureSection.current.querySelectorAll('video').forEach((video) => {
                video.play();
            });
        }
    }, [featureSectionInView]);

    const getCtaButton = () => {
        if (!!referrerData.firstName) {
            return (
                <CtaButtonStyled
                    onClick={() => {
                        dispatch(showModal({ type: ModalType.Signup }));
                    }}
                >
                    Join now
                </CtaButtonStyled>
            );
        }

        return (
            <CtaButtonStyled
                onClick={() => {
                    setShowEmailOverlay(true);

                    dispatch(
                        track({
                            event: USER_EVENTS.START_WATCHING_CLICK,
                            referrer: document.referrer,
                            referrerId: referrerData.id,
                            referralLink,
                            campaign: ac === AdCampaignSource.Facebook ? 'fb' : null,
                        })
                    );
                }}
            >
                Become a Playback Creator
            </CtaButtonStyled>
        );
    };

    const ctaButton = getCtaButton();

    return (
        <BetaStyled className={className}>
            <SEO />
            <GlobalStyles />
            <Nav signup={!!referrerData.firstName} login={!loggedIn} />
            <ScrolledNavStyled visible={navScrolled}>
                <LogoMiniContainerStyled>
                    <LogoMini />
                </LogoMiniContainerStyled>
                <LogoContainerStyled>
                    <Logo />
                </LogoContainerStyled>
                {ctaButton}
                {!loggedIn && (
                    <AuthButtonsStyled
                        signup={!!referrerData.firstName}
                        onLogin={() => {
                            dispatch(
                                track({
                                    event: 'Click Log In',
                                    context: TrackingContext.Nav,
                                })
                            );
                        }}
                    />
                )}
            </ScrolledNavStyled>
            <HeroStyled ref={heroSection}>
                <HeaderSectionStyled>
                    <PrimaryHeadingStyled>{getHeading()}</PrimaryHeadingStyled>
                    <DescriptionStyled>
                        Playback is where communities of creators, fans and friends hang out and
                        watch games together. Want to stream with your community? Apply to be a
                        Playback Creator!
                    </DescriptionStyled>
                    {ctaButton}
                </HeaderSectionStyled>
                <HeroAvatarsStyled>
                    <MarketingChatBubbleStyled
                        delay={0.5}
                        src={avatar1Src}
                        visible
                        mobileVisible
                        preload
                        onCanPlay={handleHeroSectionVideoCanPlay}
                    />
                    <MarketingChatBubbleStyled
                        delay={0.7}
                        src={avatar2Src}
                        visible
                        mobileVisible
                        preload
                        onCanPlay={handleHeroSectionVideoCanPlay}
                    />
                    <MarketingChatBubbleStyled
                        delay={0.9}
                        src={avatar3Src}
                        visible
                        mobileVisible
                        preload
                        onCanPlay={handleHeroSectionVideoCanPlay}
                    />
                </HeroAvatarsStyled>
                <HeroVideoContainerStyled>
                    <HeroVideoStyled
                        src={heroVideoSrc}
                        ref={heroVideo}
                        muted
                        loop
                        playsInline
                        controls={false}
                        preload="auto"
                        onCanPlayThrough={handleHeroSectionVideoCanPlay}
                    />
                </HeroVideoContainerStyled>
            </HeroStyled>
            <AppsSectionStyled>
                <AppsHeadingStyled>Stream from nearly any TV service, including:</AppsHeadingStyled>
                <AppIconsStyled>
                    {apps.map((app) => (
                        <AppIconWrapperStyled key={app.name}>
                            <AppIconStyled src={app.icon} alt={app.name} />
                        </AppIconWrapperStyled>
                    ))}
                </AppIconsStyled>
            </AppsSectionStyled>
            <FeatureSectionStyled ref={setFeatureSectionRef}>
                <StickyGraphicStyled>
                    <GraphicContainerStyled>
                        <FeatureVideoContainerStyled
                            blur={devicesInView}
                            mobileBlur={videoState === 3}
                            darken={videoChatInView && darkenVideo}
                        >
                            <FeatureVideoStyled
                                ref={setVideoRef}
                                src={featureSectionInView ? heroVideoSrc : null}
                                muted
                                playsInline
                                loop
                                controls={false}
                                title="Playback Demo Video"
                            />
                        </FeatureVideoContainerStyled>
                        <CaptureStateStyled
                            visible={captureInView}
                            mobileVisible={videoState === 1}
                        >
                            <CapturingPillStyled>
                                <PlayIcon /> Sharing stream
                            </CapturingPillStyled>
                        </CaptureStateStyled>
                        <ChatBubblesStyled
                            visible={videoChatInView}
                            mobileVisible={videoState === 2}
                        >
                            <MarketingChatBubbleStyled
                                visible={videoChatInView}
                                mobileVisible={videoState === 2}
                                delay={0.5}
                                src={
                                    (mobile ? videoState === 2 : videoChatInView)
                                        ? avatar1Src
                                        : null
                                }
                                autoPlay
                            />
                            <MarketingChatBubbleStyled
                                visible={videoChatInView}
                                mobileVisible={videoState === 2}
                                delay={1}
                                src={
                                    (mobile ? videoState === 2 : videoChatInView)
                                        ? avatar2Src
                                        : null
                                }
                                autoPlay
                            />
                            <MarketingChatBubbleStyled
                                visible={videoChatInView}
                                mobileVisible={videoState === 2}
                                delay={1.5}
                                src={
                                    (mobile ? videoState === 2 : videoChatInView)
                                        ? avatar3Src
                                        : null
                                }
                                autoPlay
                            />
                        </ChatBubblesStyled>
                        <DeviceStyled visible={devicesInView} mobileVisible={videoState === 3}>
                            <DeviceInnerStyled>
                                <DeviceNotchStyled />
                                <GraphicContainerStyled>
                                    <MiniVideoStyled ref={canvas} />
                                </GraphicContainerStyled>
                                <DeviceBubblesStyled>
                                    <MarketingChatBubbleStyled
                                        visible={devicesInView}
                                        mobileVisible={videoState === 3}
                                        delay={0}
                                        src={
                                            (mobile ? videoState === 3 : devicesInView)
                                                ? avatar1Src
                                                : null
                                        }
                                        autoPlay
                                    />
                                    <MarketingChatBubbleStyled
                                        visible={devicesInView}
                                        mobileVisible={videoState === 3}
                                        delay={0.25}
                                        src={
                                            (mobile ? videoState === 3 : devicesInView)
                                                ? avatar2Src
                                                : null
                                        }
                                        autoPlay
                                    />
                                    <MarketingChatBubbleStyled
                                        visible={devicesInView}
                                        mobileVisible={videoState === 3}
                                        delay={0.5}
                                        src={
                                            (mobile ? videoState === 3 : devicesInView)
                                                ? avatar3Src
                                                : null
                                        }
                                        autoPlay
                                    />
                                </DeviceBubblesStyled>
                                <DeviceButtonsStyled>
                                    <DeviceButtonStyled>
                                        <CameraIcon />
                                    </DeviceButtonStyled>
                                    <DeviceButtonStyled>
                                        <MicIcon />
                                    </DeviceButtonStyled>
                                    <DeviceButtonStyled negative>
                                        <HandIcon />
                                    </DeviceButtonStyled>
                                </DeviceButtonsStyled>
                            </DeviceInnerStyled>
                        </DeviceStyled>
                    </GraphicContainerStyled>
                </StickyGraphicStyled>
                <FeatureBlocksStyled>
                    <MarketingFeatureDetails
                        index={0}
                        ref={captureRef}
                        visible={captureInView}
                        icon={<PlayIcon />}
                        heading="Stream in perfect sync"
                        description="Share your room link and watch with your community in realtime — without spoilers."
                    />
                    <MarketingFeatureDetails
                        index={1}
                        ref={videoChatRef}
                        visible={videoChatInView}
                        icon={<HandIcon />}
                        heading="Voice, video & text chat"
                        description="Join the Stage and talk along with voice and video, and invite viewers up for Q&A."
                    />
                    <MarketingFeatureDetails
                        index={2}
                        ref={devicesRef}
                        visible={devicesInView}
                        icon={<PhoneIcon />}
                        heading="Join on any device"
                        description="Viewers can stream from the mobile app or from any web browser."
                    />
                </FeatureBlocksStyled>
            </FeatureSectionStyled>
            <FooterBannerStyled>
                <FooterBannerTextStyled>
                    Building a better way to watch together.
                </FooterBannerTextStyled>
                {ctaButton}
            </FooterBannerStyled>
            <Footer />
            {showEmailOverlay && (
                <BetaEmailOverlay
                    visible
                    onClose={() => {
                        setShowEmailOverlay(false);
                    }}
                    onSubmit={handleSubmitEmail}
                />
            )}
        </BetaStyled>
    );
};

const GlobalStyles = createGlobalStyle`
    html {
        scroll-snap-type: y proximity;
        background-color: ${({ theme }) => theme.palette.DarkGrey2};
    }
`;

const MarketingChatBubbleStyled = styled(MarketingChatBubble)``;

const CtaButtonStyles = css`
    ${({ theme }) => theme.typography.FontMedium};
    font-size: ${rem(18)};
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    align-self: stretch;
    height: ${rem(60)};
    border-radius: ${rem(30)};
    flex-shrink: 0;
    color: ${({ theme }) => theme.palette.White};
    background-color: ${({ theme }) => theme.palette.Red};
    transition: background-color 50ms ease;
    flex-shrink: 0;
    white-space: nowrap;

    nav & {
        min-width: ${rem(132)};
        padding: 0 ${rem(20)};
        height: ${rem(40)};
        border-radius: ${rem(20)};
        font-size: ${rem(13)};
        align-self: center;
        margin-left: auto;
    }

    svg {
        height: ${rem(24)};
        width: ${rem(24)};
        margin-right: ${rem(5)};

        .svg_content {
            fill: ${({ theme }) => theme.palette.White};
        }
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        height: ${rem(50)};
        width: ${rem(220)};
        border-radius: ${rem(25)};
        font-size: ${rem(14)};
        align-self: flex-start;
        box-shadow: ${rem(-1)} 0 ${rem(2)} rgba(0, 0, 0, 0.4);

        nav & {
            align-self: center;
            height: ${rem(50)};
            min-width: ${rem(220)};
            padding: 0 ${rem(24)};
            border-radius: ${rem(25)};
            font-size: ${rem(14)};
        }
    }

    @media (hover: hover) {
        &:hover {
            background-color: ${({ theme }) => transparentize(0.2, theme.palette.Red)};
        }
    }
`;

const EmailSubmissionStyled = styled(EmailSubmission)`
    align-self: stretch;
`;

const CtaButtonStyled = styled.button`
    ${CtaButtonStyles}
`;

const DescriptionStyled = styled.p`
    color: ${({ theme }) => theme.palette.LightGrey1};
    font-size: ${rem(18)};
    line-height: 1.5;
    width: 100%;
    margin-bottom: ${rem(24)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        color: ${({ theme }) => theme.palette.White};
        max-width: 85%;
        margin-bottom: ${rem(32)};
        text-shadow: 0 0 ${rem(2)} rgba(0, 0, 0, 0.4);
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.XXLarge + 200)}) {
        font-size: ${rem(20)};
    }
`;

const PrimaryHeadingStyled = styled.h1`
    color: ${({ theme }) => theme.colors.White1};
    font-size: ${rem(40)};
    letter-spacing: ${rem(-1.5)};
    line-height: 1.2;
    margin-bottom: ${rem(24)};
    max-width: ${rem(360)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        font-size: ${rem(54)};
        line-height: 1.11;
        max-width: ${rem(480)};
        text-shadow: 0 0 ${rem(5)} rgba(0, 0, 0, 0.4);
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        font-size: ${rem(72)};
        max-width: none;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.XXLarge + 200)}) {
        font-size: ${rem(90)};
    }
`;

const FooterBannerTextStyled = styled(PrimaryHeadingStyled)`
    color: ${({ theme }) => theme.palette.Red};
    margin-bottom: ${rem(24)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        max-width: ${rem(640)};
        margin-bottom: ${rem(36)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.XXLarge + 200)}) {
        max-width: ${rem(720)};
        margin-bottom: ${rem(64)};
    }
`;

const FooterBannerStyled = styled.div`
    background-color: ${({ theme }) => theme.palette.RedTransparent1};
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: ${rem(40)} ${rem(24)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        padding: ${rem(64)} ${rem(40)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.XXLarge + 200)}) {
        padding: ${rem(120)} ${rem(80)};
    }
`;

const MiniVideoStyled = styled.canvas`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: ${rem(8)};
`;

const FeatureVideoStyled = styled.video`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: ${rem(12)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        border-radius: ${rem(20)};
    }
`;

const FeatureVideoContainerStyled = styled.div<{
    blur: boolean;
    mobileBlur: boolean;
    darken: boolean;
}>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transition: filter 500ms ease;
    filter: blur(0px) brightness(100%);
    border-radius: ${rem(12)};

    @media screen and (max-width: ${({ theme }) => rem(theme.breakpoints.Small - 1)}) {
        ${({ mobileBlur }) =>
            mobileBlur &&
            css`
                filter: blur(${rem(10)}) brightness(100%);
            `}
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        border-radius: ${rem(20)};

        ${({ blur }) =>
            blur &&
            css`
                filter: blur(${rem(10)}) brightness(100%);
            `}

        ${({ darken }) =>
            darken &&
            css`
                filter: blur(0px) brightness(20%);
            `}
    }
`;

const GraphicContainerStyled = styled.div`
    background-color: ${({ theme }) => theme.palette.MedGrey2};
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    box-shadow: ${rem(4)} ${rem(4)} ${rem(34)} ${rem(14)} rgba(0, 0, 0, 0.3);
    border-radius: ${rem(12)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        border-radius: ${rem(20)};
    }
`;

const ChatBubblesStyled = styled.div<{ visible: boolean; mobileVisible: boolean }>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 4%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    opacity: ${({ mobileVisible }) => (mobileVisible ? 1 : 0)};
    transition: opacity 500ms ease;

    ${MarketingChatBubbleStyled} {
        height: 33.17%;
        width: 17%;
    }

    > * + * {
        margin-left: ${rem(6)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        opacity: ${({ visible }) => (visible ? 1 : 0)};
    }
`;

const DeviceButtonStyled = styled.div<{ negative?: boolean }>`
    height: 100%;
    width: 23.34%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme, negative }) =>
        negative ? theme.palette.RedTransparent1 : theme.palette.DarkGrey1};
    border-radius: ${rem(16)};

    svg {
        height: 45%;
        width: 45%;
    }

    .svg_content {
        fill: ${({ theme, negative }) => (negative ? theme.palette.Red : theme.palette.White)};
    }
`;

const DeviceButtonsStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 12%;
    margin-top: 7%;

    > * + * {
        margin-left: 6%;
    }
`;

const DeviceBubblesStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 50%;
    margin-top: 14%;
    justify-content: center;

    ${MarketingChatBubbleStyled} {
        width: 46%;
        height: 47.31%;

        &:first-of-type {
            margin: 0 10% -7% 10%;
        }

        &:last-of-type {
            margin-left: 4%;
        }
    }
`;

const DeviceNotchStyled = styled.div`
    background-color: ${({ theme }) => theme.palette.Black};
    height: 10%;
    width: 58%;
    position: absolute;
    top: -15%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: ${rem(10)};
`;

const DeviceInnerStyled = styled.div`
    position: absolute;
    height: 84%;
    width: 84%;
    top: 8%;
    right: 8%;
    bottom: 8%;
    left: 8%;

    ${GraphicContainerStyled} {
        box-shadow: none;
        border-radius: ${rem(8)};
    }
`;

const DeviceStyled = styled.div<{ visible: boolean; mobileVisible: boolean }>`
    position: absolute;
    top: 10%;
    right: 8%;
    width: 36%;
    height: 0;
    padding-bottom: 70%;
    background-color: ${({ theme }) => theme.palette.MedGrey4};
    box-shadow: ${rem(4)} ${rem(4)} ${rem(34)} ${rem(14)} rgba(0, 0, 0, 0.3);
    border-radius: ${rem(20)};
    overflow: hidden;
    opacity: ${({ mobileVisible }) => (mobileVisible ? 1 : 0)};
    transition: opacity 500ms ease;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        opacity: ${({ visible }) => (visible ? 1 : 0)};
        left: 10%;
        top: 19%;
        right: 10%;
    }
`;

const CaptureStateStyled = styled.div<{ visible: boolean; mobileVisible: boolean }>`
    position: absolute;
    top: ${rem(-1)};
    right: ${rem(-1)};
    bottom: ${rem(-1)};
    left: ${rem(-1)};
    box-sizing: border-box;
    border: ${({ mobileVisible }) => rem(mobileVisible ? 5 : 0)} solid
        ${({ theme }) => theme.palette.Green};
    opacity: ${({ mobileVisible }) => (mobileVisible ? 1 : 0)};
    transition: opacity 500ms ease, border-width 250ms ease;
    border-radius: ${rem(12)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        opacity: ${({ visible }) => (visible ? 1 : 0)};
        border-radius: ${rem(20)};
        border: ${({ visible }) => rem(visible ? 8 : 0)} solid ${({ theme }) => theme.palette.Green};
    }
`;

const CapturingPillStyled = styled.div`
    height: ${rem(28)};
    width: ${rem(140)};
    border-radius: ${rem(14)};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${transparentize(0.2, '#203125')};
    color: ${({ theme }) => theme.palette.Green};
    font-size: ${rem(14)};
    position: absolute;
    top: 6%;
    left: 3%;
    box-shadow: ${rem(-1)} 0 ${rem(2)} rgba(0, 0, 0, 0.4);

    svg {
        height: ${rem(16)};
        width: ${rem(16)};
        margin-right: ${rem(4)};

        .svg_content {
            fill: ${({ theme }) => theme.palette.Green};
        }
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        height: ${rem(32)};
        width: ${rem(160)};
        border-radius: ${rem(16)};
        font-size: ${rem(15)};
        line-height: ${rem(32)};

        svg {
            height: ${rem(18)};
            width: ${rem(18)};
            margin-top: ${rem(1)};
            margin-right: ${rem(4)};
        }
    }
`;

const StickyGraphicStyled = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: calc(0.5625 * (100vw - ${rem(40)}) + ${rem(72)});
    z-index: 2;
    position: absolute; // fallback
    position: sticky;
    top: 0;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        height: 100vh;
        width: 63%;
        margin-right: ${rem(40)};
        justify-content: center;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        margin-right: ${rem(60)};
    }
`;

const FeatureBlocksStyled = styled.div`
    padding-top: ${rem(60)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        max-height: 233.33vh;
        width: 37%;
        max-width: ${rem(280)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.XXLarge + 200)}) {
        max-width: ${rem(480)};
    }
`;

const FeatureSectionStyled = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${rem(0)} ${rem(20)} ${rem(80)};
    z-index: 2;
    position: relative;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        height: auto;
        padding: 0 ${rem(40)} ${rem(60)};
        max-width: ${rem(1080)};
        margin: 0 auto;
        position: relative;
        flex-direction: row;
        justify-content: center;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Medium)}) {
        padding: 0 ${rem(60)} ${rem(100)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        max-width: ${rem(1280)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.XXLarge + 200)}) {
        max-width: ${rem(1440)};
    }
`;

const AppIconStyled = styled.img`
    height: ${rem(60)};
    width: ${rem(120)};
`;

const AppIconWrapperStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${rem(20)} ${rem(12)};

    @media (hover: hover) {
        &:hover {
            transform: scale(1.1);
            transition: transform 200ms ease;
        }
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        padding: ${rem(30)};
    }
`;

const AppIconsStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: ${rem(960)};
    padding: ${rem(10)};
    margin: 0 auto;

    @media screen and (min-width: ${rem(1800)}) {
        max-width: none;
    }
`;

const AppsHeadingStyled = styled.h2`
    ${({ theme }) => theme.typography.Paragraph13}
    padding-top: ${rem(40)};
    text-align: center;
    margin: 0 auto;
`;

const AppsSectionStyled = styled.div`
    background-color: ${({ theme }) => theme.palette.DarkGrey1};
`;

const HeaderSectionStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    text-align: left;
    box-sizing: border-box;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-right: ${rem(20)};
        padding: ${rem(60)} 0 ${rem(120)};
        max-width: ${rem(640)};
        z-index: 1;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        flex-shrink: 0;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.XXLarge + 200)}) {
        max-width: ${rem(720)};
    }
`;

const HeroAvatarsStyled = styled.div`
    z-index: 2;
    display: flex;
    position: absolute;
    bottom: ${rem(-12)};
    left: 50%;
    transform: translateX(-50%);

    ${MarketingChatBubbleStyled} {
        width: ${rem(80)};
        height: ${rem(80)};
        margin: 0 ${rem(4)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        flex-direction: column;
        position: relative;
        bottom: auto;
        left: auto;
        transform: none;

        ${MarketingChatBubbleStyled} {
            margin-top: ${rem(-28)};
            height: ${rem(144)};
            width: ${rem(144)};
            border-width: ${rem(6)};
            box-shadow: ${rem(-1)} 0 ${rem(2)} rgba(0, 0, 0, 0.4);

            & + & {
                margin-left: 0;
            }
        }
    }
`;

const HeroVideoStyled = styled.video`
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity 100ms ease 200ms;
    border-radius: ${rem(12)};
    overflow: hidden;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        border-radius: 0;
    }
`;

const HeroVideoContainerStyled = styled.div`
    display: flex;
    border-radius: ${rem(12)};
    overflow: hidden;
    margin-top: ${rem(36)};
    width: calc(100vw - ${rem(48)});
    height: calc(0.5625 * (100vw - ${rem(48)}));
    position: relative;
    transform: translate3d(0, 0, 0);

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        position: absolute;
        top: ${rem(-80)};
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        filter: brightness(0.4);
        border-radius: 0;
        margin-top: 0;
        height: auto;
        width: auto;
    }
`;

const HeroStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: ${rem(24)};
    margin-bottom: ${rem(60)};
    position: relative;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 ${rem(40)};
        position: relative;
        min-height: ${rem(400)};
        height: calc(100vh - ${rem(280)});
        max-height: ${rem(800)};
        box-sizing: border-box;
        margin-bottom: 0;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.XXLarge + 200)}) {
        padding: 0 ${rem(80)};
    }
`;

const LogoMiniContainerStyled = styled.div`
    height: ${rem(21)};
    width: ${rem(24)};
    display: flex;

    svg {
        height: 100%;
        width: 100%;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        display: none;
    }
`;

const LogoContainerStyled = styled.div`
    display: none;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Medium)}) {
        height: ${rem(24)};
        width: ${rem(260)};
        display: flex;

        svg {
            height: 100%;
            width: 100%;
        }
    }
`;

const AuthButtonsStyled = styled(AuthButtons)`
    margin-left: ${rem(12)};

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin-left: ${rem(16)};
    }
`;

const ScrolledNavStyled = styled.nav<{ visible: boolean }>`
    height: ${rem(72)};
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 ${rem(24)};
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: ${({ theme }) => theme.palette.DarkGrey2};
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: opacity 200ms ease;
    pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};

    ${CtaButtonStyled} {
        display: none;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        height: ${rem(80)};

        ${CtaButtonStyled} {
            display: flex;
        }
    }
`;

const BetaStyled = styled.div`
    background-color: ${({ theme }) => theme.palette.DarkGrey2};
    min-height: 100vh;
`;

Landing.Layout = StandardLayout;

export default Landing;
